import { ChevronLeft, ChevronRight, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/styles';
import { map } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { connect } from 'react-redux';

import { CustomTable, SplitButton } from '../../../../utils/components';
import { AlertDialogV2 } from '../../../../utils/components/AlertDialogV2';
import { AMBIENTE, config_ds, DATASALES_CRM_API } from '../../../../utils/helpers/Constants';
import api, { Fetch } from '../../../../utils/helpers/Fetch';
import { toBase64 } from '../../../../utils/helpers/Functions';
//actions
import { postProdutoImagemTabloide } from '../../../TabloideDigitalV2/action';
import AdicionarProduto from '../../../TabloideDigitalV2/components/AdicionarProduto';
import DialogBackgroundRemove from './DialogBackgroundRemove';
import { FindBackgrounds } from './FindBackgrounds';
import { Segments } from './FindBackgrounds/types';
import FindLayouts from './FindLayouts';
import Loader from './Loader';
import { ProductsText } from './ProductsText';
import { ProductsImport } from './ProductsXLSX';
import { RodapePersonalizedDialog } from './RodapePersonalizedDialog';
import SelecionarProdutos from './SelecionarProdutos';
import './styles.css';
import TagDialog from './TagDialog';
import TagPersonalizedDialog from './TagPersonalizedDialog';

function hexToRgb(hex: string) {
  return hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$i/, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r, g, b) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

const Div = styled('div')(({ theme }) => ({
  ...theme.typography
}));

const Img = styled('img')({});

const style_titulo = (actual = true, active = true) => ({
  'font-size': '16px',
  'font-weight': actual ? '500' : '200',
  'line-height': '26px',
  'letter-spacing': '0.05em',

  marginLeft: -10,
  marginTop: 4,
  marginBottom: 16,
  ...(active ? { cursor: 'pointer' } : {})
});

const style_subtext = {
  'font-size': '14px',
  'font-weight': '400',
  'line-height': '22px',
  'letter-spacing': '0.05em',
  'text-align': 'left'
};

const style_box = {
  width: '100%',
  padding: 24,
  // backgroundColor: '#F9F9F9',
  border: '2px solid #F5F5F5',
  borderRadius: 8,
  marginBottom: 16
};

const style_link = {
  'font-size': '14px',
  'font-weight': '400',
  'line-height': '22px',
  'letter-spacing': '0.05em',
  'text-align': 'center',
  color: '#2813AD'
};

const style_info = {
  'font-size': '12px',
  'font-weight': '500',
  'line-height': '15px',
  'letter-spacing': '0.05em',
  'text-align': 'left'
};

const style_line = {
  height: '100%',
  borderLeft: '2px solid #F5F5F5',
  marginLeft: 10,
  marginTop: 8
};

const style_circle = {
  width: 25,
  height: 25,
  background: '#2813AD',
  color: 'white',
  border: '1px solid #2813AD',
  borderRadius: 32,

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 0,

  cursor: 'pointer'
};

const style_side_div = {
  overflow: 'hidden',
  margin: 4,
  marginRight: 0,
  width: 50,
  minHeight: 60
};

const style_button_1 = {
  width: 180,
  background: '#F5F5F5',
  color: '#616161',
  borderRadius: '4px',
  fontSize: 14,
  fontWeight: 400,
  '&:hover': {
    filter: 'brightness(0.8)',
    background: '#F5F5F5'
  }
};

const style_color_div = {
  border: '1px solid #CFCFCF',
  borderRadius: '4px',
  marginTop: '10px',
  padding: '5px'
};

const style_color_div_video = {
  border: '1px solid #CFCFCF',
  borderRadius: '4px',
  marginTop: '10px',
  padding: '5px'
};

const style_title_color = {
  marginBottom: '12px',
  textAlign: 'center',
  fontWeight: '600',
  marginTop: '5px'
};

const style_aplicar_button = {
  textAlign: 'end',
  marginTop: '8px'
};

function CreateArt({
  open,
  onCloseReturn,
  handleClose,
  formatType,
  offerId,
  modelo_pronto,
  isManual,
  onClickProduct,
  postProdutoImagemTabloide
}: {
  open: boolean;
  onCloseReturn: () => void;
  handleClose: (func?: any) => void;
  formatType: string;
  offerId: number;
  modelo_pronto: boolean;
  isManual: boolean;
  onClickProduct: (func1?: any, func2?: any) => void;
  postProdutoImagemTabloide: ({}) => any;
}) {
  const [openFindBackgrounds, setOpenFindBackgrounds] = useState(false);
  const [backgroundSelected, setBackgroundSelected] = useState<any>(null);
  const [openFindLayouts, setOpenFindLayouts] = useState(false);
  const [layoutSelected, setLayoutSelected] = useState<any>(null);
  const [productsList, setProductsList] = useState<any>([]);
  const [productsListLoading, setProductsListLoading] = useState<any>([]);
  const [storesList, setStoresList] = useState<any>([]);
  const [storeSelected, setStoreSelected] = useState<any>(null);
  const [tagsList, setTagsList] = useState<any>([]);
  const [tagSelected, setTagSelected] = useState<any>(null);
  const [rodapeSelected, setRodapeSelected] = useState<any>(null);
  const [idsNaoIncluidos, setIdsNaoIncluidos] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openTagDialog, setOpenTagDialog] = useState(false);
  const [openSelecionarProdutos, setOpenSelecionarProdutos] = useState(false);
  const [openDialogContinuar, setOpenDialogContinuar] = useState(false);
  const [previewImageFocus, setPreviewImageFocus] = useState(0);
  const [boxInFocus, setBoxInFocus] = useState(0);
  const [logoBase64, setLogoBase64] = useState(null);
  const [backgroundRemoveOpen, setBackgroundRemoveOpen] = useState(false);
  const [changeTagPersonalized, setChangeTagPersonalized] = useState(0);
  const [etiquetaTab, changeEtiquetaTab] = useState(0);
  const [openAdicionarProdutos, setOpenAdicionarProdutos] = useState(false);
  const [produtoFoco, setProdutoFoco] = useState<null | {}>(null);
  const [, updateState] = useState();
  const [submiting, setSubmiting] = useState(false);
  const [refreshPreviwPosFetchCount, setRefreshPreviwPosFetchCount] = useState(0);

  const [preview, setPreview] = useState<any>(null);
  const [openPreview, setOpenPreview] = useState<any>(false);
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false);

  const [appliedColor, setAppliedColor] = useState(0);

  const handleOpenAdicionarProdutos = () => {
    setOpenAdicionarProdutos(true);
  };
  const handleCloseAdicionarProdutos = () => {
    setOpenAdicionarProdutos(false);
  };

  const handleOpenImportarTexto = () => {
    setImportarTexto(true);
  };
  const handleCloseImportarTexto = () => {
    setImportarTexto(false);
  };

  const handleDialogImportarArquivo = (action = false) => {
    setImportarArquivo(action);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openToGenerate = Boolean(anchorEl);
  const handleClickGenerate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseGenerate = () => {
    setAnchorEl(null);
  };

  const [colorText, setColorText] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1
  });
  const [colorText2, setColorText2] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1
  });
  const [colorPrice, setColorPrice] = useState({
    r: 255,
    g: 255,
    b: 255,
    a: 1
  });

  const [produtos, setProdutos] = React.useState(null);
  const [importarTexto, setImportarTexto] = useState(false);
  const [importarArquivo, setImportarArquivo] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState([false, () => {}]);
  const { enqueueSnackbar } = useSnackbar();
  const [segments, setSegments] = useState<Segments[]>([]);
  async function fetchSegments() {
    try {
      let listSegments: Segments[] = [];
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/segments`).then(response => {
        response.content.map((value: { id: any; name: any }) =>
          listSegments.push({ id: value.id, label: value.name })
        );
        setSegments(listSegments);
      });
    } catch (err) {
      console.log('ErrFetchSegments=>', err);
    }
  }

  useEffect(() => {
    setLayoutSelected(null);
    setBackgroundSelected(null);
    setPreview(null);
    setBoxInFocus(0);
  }, [open]);

  useLayoutEffect(() => {
    fetchSegments();
  }, []);

  async function fetchProducts({ executeHandleSubmit } = { executeHandleSubmit: false }) {
    setProductsListLoading(true);
    try {
      await api(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/getTabloidesDigitais`,
        { id_tabloide: offerId }
      ).then(response => {
        setProductsList(
          map(response[0].produtos, p => ({
            id: p.id_tabloide_produto,
            name: p.nome_produto,
            // price: currencyFormat(p.preco_original),
            price: p.preco_original,
            club_price: p.preco_dois,
            dynamics: p.dinamica_comercial,
            image_url: p.imagem_produto,
            is_active: p.ativo,
            var1: p.var1,
            var2: p.var2,
            var3: p.var3,
            var4: p.var4,
            var5: p.var5,
            var6: p.var6,
            fullData: { ...p }
          }))
        );
      });
    } catch (err) {
      console.log(err);
    }
    setProductsListLoading(false);
    if (executeHandleSubmit) {
      setRefreshPreviwPosFetchCount(prev => prev + 1);
    }
  }

  async function fetchStores(setStoreAfter = true) {
    try {
      await api(`${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/getLojas`).then(
        response => {
          setStoresList(
            map(response, s => ({
              id: s.id_loja,
              name: s.nome_loja,
              zip_code: s.cep,
              street_address: s.endereco,
              phone_number: s.whatsapp_phone_number,
              logo_url: s.logo1,
              description: s.descricao || '',
              complete: {
                ...s
              }
            }))
          );
          if (setStoreAfter) {
            const s = response[0];
            setStoreSelected({
              id: s.id_loja,
              name: s.nome_loja,
              zip_code: s.cep,
              street_address: s.endereco,
              phone_number: s.whatsapp_phone_number,
              logo_url: s.logo1,
              description: s.descricao || '',
              complete: { ...s }
            });
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function postProdutoImagem(b64: any, remove: boolean) {
    try {
      const response = await api(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postProdutoImagem`,
        {
          imagem_produto: b64,
          isBase64: true,
          isLogo: true,
          naoRemoverFundo: !remove
        },
        'POST',
        null,
        null,
        false
      );

      setStoreSelected((prev: any) => ({
        ...prev,
        logo_url: response.image
      }));
      setLogoBase64(null);

      return response;
    } catch (err) {
      console.log(err);
    }
  }

  async function putLoja(img?: any) {
    try {
      await api(
        `${DATASALES_CRM_API}pedido-p1-${AMBIENTE}/${AMBIENTE}/crm/putLojas`,
        {
          ...storeSelected.complete,
          id_loja: storeSelected.id,
          nome_loja: storeSelected.name,
          cep: storeSelected.zip_code,
          endereco: storeSelected.street_address,
          whatsapp_phone_number: storeSelected.phone_number,
          descricao: storeSelected.description || '',
          ...(img
            ? {
                logo1: {
                  ...img
                }
              }
            : {})
        },
        'PUT',
        null,
        null,
        false
      );
      await fetchStores(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmitProduct(product) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
        { produto: product },
        'POST',
        null,
        null,
        false
      );

      setProdutos(prevState => [...prevState, response.produto]);
      //alert('Produto adicionado ao final da lista!');

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });

      fetchProducts();

      enqueueSnackbar('Produto adicionado ao final da lista!', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  async function handleSubmitProducts(newProducts: any) {
    try {
      const response = {
        produtos: []
      };

      for (var x = 0; x < newProducts.length / 10; x++) {
        enqueueSnackbar('Processando...', {
          variant: 'info'
        });

        const response_x = await Fetch(
          `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/produto`,
          {
            produtos: newProducts.slice(x * 10, x * 10 + 10),
            modeloPronto: false,
            tabloidBasic: null,
            id_tabloide_digital: offerId
          },
          'POST',
          null,
          null,
          false
        );

        response.produtos = [...response.produtos, ...(response_x.produtos || [])];
      }

      setProdutos(prevState => [...prevState, ...response.produtos]);

      fetchProducts();
      setOpenSelecionarProdutos(true);
      enqueueSnackbar('Produtos adicionados ao final da lista!', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento ao importar os produtos por texto!!', {
        variant: 'error'
      });
    }
  }

  async function fetchPostProdutoImagemTabloide(data, isBase64) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      const image = await postProdutoImagemTabloide({
        ...data,
        isBase64
      });
      enqueueSnackbar('Upload de imagem com sucessoo!', {
        variant: 'success'
      });
      return image;
    } catch (err) {
      enqueueSnackbar(err.message || err.title, {
        variant: 'error'
      });
      return null;
    }
  }

  function validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  const toUpdateSequence = () => {
    let tmp = [...produtos];
    tmp.forEach((element, index) => {
      element.sequence = index;
    });
    let toUpdateSequence = null;
    toUpdateSequence = {
      products: [...tmp],
      categorias: []
    };
    return toUpdateSequence;
  };

  async function handleEditProduct(product) {
    try {
      enqueueSnackbar('Processando...', {
        variant: 'info'
      });
      await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/putTdProduto`,
        { produto: product, toUpdateSequence: toUpdateSequence() },
        'PUT',
        null,
        null,
        false
      );

      setProdutos(prevState => {
        let index = prevState.findIndex(
          element => element.id_tabloide_produto === product.id_tabloide_produto
        );
        prevState[index] = product;
        return [...prevState];
      });

      enqueueSnackbar('Aguardando...', {
        variant: 'info'
      });
      fetchProducts();

      enqueueSnackbar('Atualização concluída', {
        variant: 'success'
      });
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Falha de processamento', {
        variant: 'error'
      });
    }
  }

  useEffect(() => {
    setProdutos(prev =>
      productsList
        .map((item: any) => ({
          ...item.fullData
        }))
        .map((element, index) => {
          let tmp = idsNaoIncluidos.find(element2 => element.id_tabloide_produto === element2);
          if (tmp) {
            tmp = false;
          } else {
            tmp = true;
          }
          return {
            ...element,
            selecionado: prev && prev[index] ? prev[index].selecionado : tmp
          };
        })
    );
  }, [
    productsList.map((item: any) => ({
      ...item.fullData
    })).tabloideProdutos
  ]);

  async function updateProduto(newData: any, type: string, close: any) {
    try {
      setSubmiting(true);

      let new_image = '';
      let data = {
        ...newData,
        preco_original:
          typeof newData.preco_original === 'string'
            ? parseFloat(newData.preco_original.replace(',', '.'))
            : newData.preco_original,
        preco_dois:
          typeof newData.preco_dois === 'string'
            ? parseFloat(newData.preco_dois.replace(',', '.'))
            : newData.preco_dois
      };

      if (type === 'add') {
        data = {
          ...data,
          produto_ativo: 1
        };
      }

      let multProdutos =
        produtoFoco && produtoFoco.multProdutos ? [...produtoFoco.multProdutos] : [];
      data.forDelete = [];
      for (let i in multProdutos) {
        let atual = multProdutos[i];

        if (data.imagens) {
          let index = data.imagens.findIndex(
            element => element.id_tabloide_produto === atual.id_tabloide_produto
          );

          if (index < 0) {
            data.forDelete = [...data.forDelete, atual];
          }
        }
      }

      if (data.imagemPrincipal) {
        data = {
          ...data,
          ...data.imagemPrincipal[0]
        };

        data.imagens = [...data.imagemPrincipal, ...data.imagens];
      }

      if (data.imagens) {
        for (let i in data.imagens) {
          let image = data.imagens[i];

          if (image.new && image.imagem_produto) {
            if (Boolean(image.imagem_produto) && /;base64,/g.test(image.imagem_produto)) {
              new_image = await fetchPostProdutoImagemTabloide(image, true);
            } else {
              new_image = await fetchPostProdutoImagemTabloide(image, false);
            }
            //
            // throw 500;
            //
            let new_image_object = new_image;
            new_image = new_image.image;
            if (!new_image_object.findExistingImage) {
              let dateNow = new window.Date();
              dateNow = dateNow.getTime().toString();
              if (validURL(new_image)) new_image = new_image.split('?')[0] + '?' + dateNow;
            }

            let objTmp = {
              ...image, // pra receber o new
              imagem_produto: new_image,
              imagem_produto_original_link: new_image_object.imagem_produto_original_link,
              imagem_produto_original_s3: new_image_object.imagem_produto_original_s3,
              new: true
            };

            if (i === '0') {
              data = {
                ...data,
                ...objTmp
              };
            }

            let tmpImagens = data.imagens;
            tmpImagens[i] = {
              ...objTmp
            };

            data.imagens = [...tmpImagens];
          }
        }

        data.imagens.shift();
      }

      if (type === 'add') {
        await handleSubmitProduct({
          ...data,
          id_tabloide_digital: offerId
        });
      } else {
        const index = produtos.indexOf(produtoFoco);

        produtos[index] = data;
        setProdutos(produtos);
        await handleEditProduct(data);
      }

      setSubmiting(false);
      setOpenSelecionarProdutos(true);
      close();
    } catch (e) {
      setSubmiting(false);

      console.log(e);

      enqueueSnackbar('Falha de Processamento! Tente novamente ou tente outra imagem.', {
        variant: 'error'
      });
    }
  }

  async function importFile(file: any) {
    try {
      enqueueSnackbar('Importando o arquivo...', {
        variant: 'info'
      });
      const xlsx = await toBase64(file[0]);
      const response = await Fetch(
        `${DATASALES_CRM_API}tabloide-digital-p1-${AMBIENTE}/${AMBIENTE}/crm/postRecebeArquivo`,
        xlsx,
        'POST'
      );
      handleSubmitProducts(
        response.lista_produtos.map((product: any) => ({
          ...product,
          produto_ativo: 1,
          preco_original: product.preco,
          id_tabloide_digital: offerId,
          id_categoria: null
        }))
      );

      handleDialogImportarArquivo();
    } catch (err) {
      handleDialogImportarArquivo();
    }
  }

  async function importFileText(arrayProducts: any) {
    let newProducts = [
      ...arrayProducts.map((product: any) => ({
        id_tabloide_digital: offerId,
        nome_produto: product[0],
        preco_original: product[2],
        preco_dois: product[1] || 0,
        produto_ativo: 1,
        id_categoria: null,
        ...(product[2] ? { dinamica_comercial: 'de_por' } : {})
      }))
    ];
    handleSubmitProducts(newProducts);
  }

  async function fetchTags() {
    try {
      await api(`${DATASALES_CRM_API}editor-${AMBIENTE}/tags`).then(response => {
        setTagsList(response.content);
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchStores();
    fetchTags();
    fetchProducts();
  }, []);

  async function handleSubmitVideo() {
    try {
      let template = layoutSelected;
      let background = backgroundSelected;
      let tag = tagSelected;

      await api(
        `${DATASALES_CRM_API}lista-ofertas-p1-${AMBIENTE}/postVideomatik`,
        {
          seeRequests: false,
          id_tabloide: offerId,
          id_template: template.video_template_id,
          tipo: template.formatId,
          idsNaoIncluidos,
          composition: template.video_composition,
          logo: storeSelected && storeSelected.logo_url ? storeSelected.logo_url : null,
          fundo: background ? background.imageURL : null,
          etiqueta: tag ? tag.imageURL : null,
          texto: storeSelected.description ? storeSelected.description : null,
          ...(appliedColor
            ? {
                corTexto1: rgbToHex(colorText.r, colorText.g, colorText.b),
                corTexto2: rgbToHex(colorText2.r, colorText2.g, colorText2.b),
                corPreco1: rgbToHex(colorPrice.r, colorPrice.g, colorPrice.b)
              }
            : {
                ...(background?.cor_texto &&
                  typeof background?.cor_texto === 'string' && {
                    corTexto1: background?.cor_texto
                  }),
                ...(tag?.cor_texto &&
                  typeof tag?.cor_texto === 'string' && { corPreco1: tag?.cor_texto })
              })
        },
        'POST',
        undefined,
        undefined,
        false
      );

      enqueueSnackbar('Vídeo enviado para processamento', {
        variant: 'success'
      });

      setOpenDialogContinuar(true);

      setLoadingPreview(false);
      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Erro ao gerar vídeo', { variant: 'error' });
    }
  }

  async function handleSubmit(values: any) {
    let lastResult: any = [];
    try {
      if (values.isPreview) {
        setLoadingPreview(true);
      } else {
        setIsSubmitting(true);
      }

      handleCloseGenerate();

      let template = layoutSelected;
      let background = backgroundSelected;
      let products = productsList;
      let tag = tagSelected;

      let rgbDefaultBackground = {
        r: 0,
        g: 0,
        b: 0
      };
      if (background?.cor_texto && typeof background?.cor_texto === 'string') {
        let rgb = hexToRgb(background?.cor_texto);
        rgbDefaultBackground = {
          r: rgb[0] ?? 0,
          g: rgb[1] ?? 0,
          b: rgb[2] ?? 0
        };
      }

      let rgbDefaultTag = {
        r: 0,
        g: 0,
        b: 0
      };
      if (tag?.cor_texto && typeof background?.cor_texto === 'string') {
        let rgb = hexToRgb(tag?.cor_texto);
        rgbDefaultTag = {
          r: rgb[0] ?? 0,
          g: rgb[1] ?? 0,
          b: rgb[2] ?? 0
        };
      }

      if (!background && !modelo_pronto && !isManual) {
        throw new Error('Selecione alguma imagem de fundo');
      }

      if (!template && !isManual) {
        throw new Error(!modelo_pronto ? 'Selecione algum layout' : 'Selecione algum fundo');
      }

      if (isManual) {
        products = [];
        background = '';
        template = {
          id: 1505,
          formatId: 18,
          name: 'Manual (Em Branco)',
          designURL:
            'https://s3.amazonaws.com/datasalesio-imagens/ManualEmBranco20220406T175119298Z-8c24607b-aa42-41ff-888a-9291356ee6eb.json',
          productQuantity: 0,
          type: 'Manual'
        };
      }

      if (template?.video_template_id) {
        if (!values.isPreview) {
          await handleSubmitVideo();
        } else {
          setLoadingPreview(false);
          setIsSubmitting(false);
        }
        return;
      }

      const company_data = {
        logo_url: storeSelected?.logo_url ? storeSelected.logo_url : null,
        description: storeSelected?.description ? storeSelected.description : null,
        address: storeSelected?.street_address ? storeSelected.street_address : null,
        phone_number: storeSelected?.phone_number ? storeSelected.phone_number : null
      };

      let result = {
        outArray: [],
        jpegImage: ''
      };

      const hasDynamicsLimpo = products.findIndex(elm => elm.dynamics === 'limpo') >= 0;

      let productsHere = products.filter((k: any) => !idsNaoIncluidos.find(n => n === k.id));

      let totalQuantity = template.productQuantity
        ? productsHere.length / template.productQuantity
        : 1;

      for (let k = 0; k < totalQuantity / 10; k++) {
        const payload = {
          ...(values.isPreview ? { isBase64: 'json', pixelRatio: 0.8 } : {}),
          byGoogleCloud: true,
          isPNG: values.isPreview ? false : !!values.isPNG,
          offer_id: offerId,
          templateId: template.id,
          formatId: template.formatId,
          tagId: tag ? tag.id : null,
          tagURL: tag ? tag.imageURL : null,
          designURL: template.designURL,
          productQuantity: template.productQuantity,
          products: productsHere
            ?.slice(0, 10 * template.productQuantity)
            .map(({ fullData, ...rest }: { fullData: any }) => ({
              ...rest,
              ...(hasDynamicsLimpo ? { dynamics: 'limpo' } : {}),
              isOfertaSecreta: Boolean(fullData?.isOfertaSecreta)
            })),
          backgroundURL: background ? background.imageURL : null,
          company_data,
          colors: appliedColor
            ? {
                colorText,
                colorPrice
              }
            : {
                ...(background && {
                  colorText: background?.cor_texto
                    ? {
                        a: 1,
                        r: rgbDefaultBackground.r,
                        g: rgbDefaultBackground.g,
                        b: rgbDefaultBackground.b
                      }
                    : { a: 1, r: 0, g: 0, b: 0 }
                }),
                ...(tag && {
                  colorPrice: tag?.cor_texto
                    ? { a: 1, r: rgbDefaultTag.r, g: rgbDefaultTag.g, b: rgbDefaultTag.b }
                    : { a: 1, r: 0, g: 0, b: 0 }
                })
              },
          ...(rodapeSelected
            ? { rodape: { rodape_json_puro: rodapeSelected?.[0]?.design_url } }
            : {})
        };
        if (values.isPreview) {
          const responseProcessor = await api(
            `${DATASALES_CRM_API}flyers-lambda-${AMBIENTE}/processor`,
            payload,
            'POST',
            undefined,
            undefined,
            false
          );

          const designArray = responseProcessor.outArray.map((i: any) => {
            return api(
              `${DATASALES_CRM_API}flyers-lambda-${AMBIENTE}/thumbnail`,
              { designURL: i.design_url, pixelRatio: 0.4 },
              'POST',
              undefined,
              undefined,
              false
            );
          });

          const promiseAll = await Promise.all(designArray);

          const imageArray: { image_url: any }[] = promiseAll.map((i: any) => {
            return { image_url: i.image };
          });

          result.outArray.push(...imageArray);

          productsHere.splice(0, 10 * template.productQuantity);
        } else {
          const tmp: { outArray: [] } = await api(
            (await config_ds()).api_editor + '/flyers',
            payload,
            'POST',
            undefined,
            undefined,
            false
          );

          result.outArray.push(...tmp.outArray);

          productsHere.splice(0, 10 * template.productQuantity);
        }
      }

      if (!values.isPreview) {
        await Promise.all(
          result.outArray.map(async (r: any) =>
            lastResult.push(
              await api(
                `${DATASALES_CRM_API}editor-${AMBIENTE}/flyers`,
                {
                  offer_id: offerId,
                  name: template.name,
                  format_id: template.formatId,
                  template_id: template.id,
                  product_quantity: template.productQuantity,
                  type: formatType,
                  image_url: r.image_url,
                  json_url: r.design_url,
                  preview_url: r.preview_url,
                  background_id: background?.id
                },
                'POST',
                undefined,
                undefined,
                false
              )
            )
          )
        );

        enqueueSnackbar('Arte gerada com sucesso', { variant: 'success' });
      } else {
        setPreview(result?.outArray ? result?.outArray.map((item: any) => item.image_url) : null);

        setPreviewImageFocus(0);
      }

      if (!values.isPreview) setOpenDialogContinuar(true);

      if (values.isPreview) {
        setLoadingPreview(false);
      } else {
        setIsSubmitting(false);
      }

      if (isManual) {
        return lastResult;
      }
    } catch (err: any) {
      console.log(err);
      const message = err.message || 'Erro ao gerar arte';
      enqueueSnackbar(message, { variant: 'error' });

      setLoadingPreview(false);
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (backgroundSelected && layoutSelected) {
      handleSubmit({ isPreview: true });
    }
  }, [
    backgroundSelected,
    layoutSelected,
    // idsNaoIncluidos,
    refreshPreviwPosFetchCount,
    tagSelected,
    appliedColor,
    changeTagPersonalized,
    rodapeSelected
  ]);

  const executeThisFunction = async () => {
    if (isManual) {
      try {
        const result = await handleSubmit({});
        handleClose(result[0]?.content);
      } catch (err) {}
    }
  };

  useEffect(() => {
    executeThisFunction();
  }, [isManual]);

  const PreviewFinalImage = ({ imageWidth }: { imageWidth?: number | string }) => {
    if (!imageWidth) {
      imageWidth = 450;
    }

    if (loadingPreview) {
      return (
        <Img
          crossOrigin="anonymous"
          style={{ width: imageWidth, padding: 150 }}
          src={'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/loading-buffering.gif'}
          className={'PreviewFinalImage'}
        />
      );
    }

    if (preview && preview.length > 0) {
      return (
        <Img
          style={{ width: imageWidth, cursor: 'pointer' }}
          src={preview[previewImageFocus]}
          crossOrigin="anonymous"
          className={'PreviewFinalImage'}
          // onClick={() => {
          //   var image = new Image();
          //   image.src = preview[previewImageFocus];

          //   var w = window.open('');
          //   w.document.write(image.outerHTML);
          // }}
        />
      );
    }

    const link =
      backgroundSelected?.imageURL ||
      'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/new-create-art/no-img.png';

    return (
      <Img
        style={{ width: imageWidth, cursor: 'pointer' }}
        crossOrigin="anonymous"
        src={link}
        className={'PreviewFinalImage'}
        // onClick={() => {
        //   var image = new Image();
        //   image.src = link;

        //   var w = window.open('');
        //   w.document.write(image.outerHTML);
        // }}
      />
    );
  };

  const step1_incomplete = (!modelo_pronto ? !backgroundSelected : false) || !layoutSelected;

  const step2_incomplete = !storeSelected;

  const [rodape_etiqueta_view, set_rodape_etiqueta_view] = useState(false);

  useEffect(() => {
    async function getConfigDS() {
      try {
        set_rodape_etiqueta_view((await config_ds()).rodapes_etiquetas_personalizadas_ativada);
      } catch (err) {
        console.log({ err });
      }
    }
    getConfigDS();
  }, []);

  return (
    <>
      <Dialog fullScreen open={open} onClose={() => handleClose()}>
        <Div>
          <Div style={{ margin: 16, display: 'flex' }}>
            <ChevronLeft sx={{ color: '#2813AD' }} />
            <Link style={{ ...style_link }} onClick={onCloseReturn}>
              Voltar
            </Link>
          </Div>
          <Div
            style={{
              margin: 'auto',
              marginTop: 40,
              display: 'flex',
              maxWidth: 1100
            }}
          >
            <Div style={{ width: 450, position: 'fixed' }}>
              <Div onClick={() => setOpenPreview(true)}>
                <PreviewFinalImage />
              </Div>
              <Div style={{ textAlign: 'center' }}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (previewImageFocus > 0) {
                      setPreviewImageFocus(prev => prev - 1);
                    }
                  }}
                >
                  <ChevronLeft style={{ opacity: previewImageFocus > 0 ? 1 : 0.2 }} />
                </IconButton>
                <IconButton
                  color="primary"
                  sx={{ marginLeft: '8px' }}
                  onClick={() => {
                    if (previewImageFocus < (preview?.length || 0) - 1) {
                      setPreviewImageFocus(prev => prev + 1);
                    }
                  }}
                >
                  <ChevronRight
                    style={{ opacity: previewImageFocus < (preview?.length || 0) - 1 ? 1 : 0.2 }}
                  />
                </IconButton>
              </Div>
              {/* <Div style={{ marginTop: 16 }}>
                Esta imagem é ilustrativa. Seus produtos serão adicionados depois de gerar a imagem.
              </Div> */}
              {/* <Button
                sx={{ width: '100%', marginTop: '16px' }}
                variant="contained"
                onClick={() => setOpenFindBackgrounds(true)}
                color="info"
              >
                Escolher fundo
              </Button> */}
            </Div>
            <Div style={{ marginLeft: 450, width: 80 }}></Div>
            <Div style={{ width: '100%', paddingRight: 8 }}>
              <Div style={{ display: 'flex' }}>
                <Div style={{ ...style_side_div }}>
                  <Div style={{ ...style_circle }} onClick={() => setBoxInFocus(0)}>
                    1
                  </Div>
                  <Div style={{ ...style_line }}></Div>
                </Div>
                <Div style={{ width: '100%' }}>
                  <Div
                    style={{ ...style_titulo(boxInFocus === 0) }}
                    onClick={() => setBoxInFocus(0)}
                  >
                    Lista de produtos
                  </Div>

                  <Div style={{ ...style_box, ...(boxInFocus === 0 ? {} : { display: 'none' }) }}>
                    <Div>
                      <Div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                        <SplitButton
                          key={'list_produtos'}
                          text="Lista de produtos"
                          label="label"
                          style={{
                            marginTop: 6,
                            marginBotton: 6,
                            ...style_button_1
                          }}
                          icon
                        >
                          <MenuItem
                            key="adicionar_produto_único"
                            onClick={() => handleOpenAdicionarProdutos()}
                          >
                            Produto único
                          </MenuItem>
                          <MenuItem
                            key="adicionar_produtos_por_texto"
                            onClick={() => handleOpenImportarTexto()}
                          >
                            Adicionar produtos por texto
                          </MenuItem>
                          <MenuItem
                            key="importat_arquivo"
                            onClick={() => handleDialogImportarArquivo(true)}
                          >
                            Importar arquivo
                          </MenuItem>
                          <MenuItem
                            key="visualizar_lista"
                            onClick={() => setOpenSelecionarProdutos(true)}
                          >
                            Visualizar lista
                          </MenuItem>
                        </SplitButton>
                        <Div style={{ marginLeft: 8, fontSize: 12 }}>
                          {productsList.length - idsNaoIncluidos.length} produto(s) selecionado(s)
                        </Div>
                      </Div>
                      <Div style={{ marginLeft: 8, fontSize: 12 }}>*Obrigatório</Div>

                      <Div style={{ marginTop: 16 }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setBoxInFocus(1);
                          }}
                          color="info"
                        >
                          Continuar
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>

              <Div style={{ display: 'flex' }}>
                <Div style={{ ...style_side_div }}>
                  <Div style={{ ...style_circle }} onClick={() => setBoxInFocus(1)}>
                    2
                  </Div>
                  <Div style={{ ...style_line }}></Div>
                </Div>
                <Div style={{ width: '100%' }}>
                  <Div
                    style={{ ...style_titulo(boxInFocus === 1) }}
                    onClick={() => setBoxInFocus(1)}
                  >
                    {!modelo_pronto ? 'Arte e Layout' : 'Criar arte de Modelo Pronto'}
                  </Div>

                  <Div style={{ ...style_box, ...(boxInFocus === 1 ? {} : { display: 'none' }) }}>
                    <Div>
                      {!modelo_pronto && (
                        <>
                          <Div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                            <Button
                              sx={{
                                marginTop: '8px',
                                ...style_button_1
                              }}
                              variant="contained"
                              onClick={() => setOpenFindBackgrounds(true)}
                              color="info"
                            >
                              Escolher fundo
                            </Button>

                            {backgroundSelected && (
                              <CheckCircleIcon sx={{ marginLeft: '8px', color: 'green' }} />
                            )}
                          </Div>
                          <Div style={{ marginLeft: 8, fontSize: 12 }}>*Obrigatório</Div>
                        </>
                      )}

                      <>
                        <Div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                          <Button
                            sx={{
                              marginTop: '8px',
                              ...style_button_1
                            }}
                            variant="contained"
                            onClick={() => setOpenFindLayouts(true)}
                            color="info"
                          >
                            {!modelo_pronto ? 'Escolher layout' : 'Escolher fundo'}
                          </Button>

                          {layoutSelected && (
                            <CheckCircleIcon sx={{ marginLeft: '8px', color: 'green' }} />
                          )}
                        </Div>
                        <Div style={{ marginLeft: 8, fontSize: 12 }}>*Obrigatório</Div>
                      </>

                      <Div style={{ marginTop: 16 }}>
                        <Button
                          variant="text"
                          onClick={() => {
                            setLayoutSelected(null);
                            setBackgroundSelected(null);
                            setPreview(null);
                          }}
                          color="info"
                        >
                          Limpar seção
                        </Button>
                        {!layoutSelected?.video_template_id && (
                          <Button
                            variant="contained"
                            style={{ marginLeft: 16 }}
                            onClick={() => {
                              !step1_incomplete && setBoxInFocus(2);
                            }}
                            disabled={step1_incomplete}
                            color="info"
                          >
                            Continuar
                          </Button>
                        )}
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>

              <Div style={{ display: 'flex' }}>
                <Div style={{ ...style_side_div }}>
                  <Div
                    style={{ ...style_circle }}
                    onClick={() => !step1_incomplete && setBoxInFocus(2)}
                    // onClick={() => setBoxInFocus(2)}
                  >
                    3
                  </Div>
                  <Div style={{ ...style_line }}></Div>
                </Div>
                <Div style={{ width: '100%' }}>
                  <Div
                    style={{ ...style_titulo(boxInFocus === 2) }}
                    onClick={() => !step1_incomplete && setBoxInFocus(2)}
                  >
                    Descrição
                  </Div>
                  <Div
                    style={{
                      ...style_box,
                      ...(boxInFocus === 2 ? {} : { display: 'none' })
                    }}
                  >
                    <Div>
                      <form>
                        <Div style={{ textAlign: 'center' }}>
                          <Img
                            style={{ marginTop: 8, width: 160 }}
                            crossOrigin="anonymous"
                            src={
                              logoBase64 ||
                              storeSelected?.logo_url ||
                              'https://datasalesio-imagens.s3.amazonaws.com/tmp-jhgs/new-create-art/logoSmallBlue.png'
                            }
                          />

                          <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            type="file"
                            onChange={async e => {
                              const imageEdit = await toBase64(e.target.files[0]);
                              setLogoBase64(imageEdit.base64);
                              setBackgroundRemoveOpen(true);
                            }}
                          />
                          <label htmlFor="contained-button-file">
                            <Div
                              style={{
                                'font-size': '14px',
                                'font-weight': '500',
                                'line-height': '22px',
                                'letter-spacing': '0.05em',
                                'text-align': 'center',
                                color: '#127FFF',
                                cursor: 'pointer',
                                marginTop: 8
                              }}
                            >
                              Adicionar logo
                            </Div>
                          </label>
                        </Div>

                        <Div style={{ marginTop: 16 }}>
                          <Autocomplete
                            id="combo-box-demo"
                            sx={{
                              bgcolor: 'white',
                              width: '100%',
                              marginBottom: '16px'
                            }}
                            options={storesList}
                            getOptionLabel={(option: any) => option?.name}
                            value={storeSelected}
                            onChange={(e, value: any) => {
                              setStoreSelected(value);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={'Selecione a loja'}
                                variant="outlined"
                              />
                            )}
                          />
                        </Div>

                        <Div style={{ marginTop: 8 }}>
                          <TextField
                            id="outlined-name"
                            label="Endereço"
                            value={storeSelected?.street_address}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              bgcolor: 'white',
                              width: '100%',
                              marginBottom: '8px'
                            }}
                            onChange={e =>
                              setStoreSelected({
                                ...storeSelected,
                                street_address: e.target.value
                              })
                            }
                          />
                        </Div>

                        <Div style={{ marginTop: 8 }}>
                          <TextField
                            id="outlined-name"
                            label="Celular"
                            value={storeSelected?.phone_number}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              bgcolor: 'white',
                              width: '100%',
                              marginBottom: '8px'
                            }}
                            onChange={e =>
                              setStoreSelected({
                                ...storeSelected,
                                phone_number: e.target.value
                              })
                            }
                          />
                        </Div>

                        <Div style={{ marginTop: 8 }}>
                          <TextField
                            id="outlined-name"
                            label="Descrição"
                            value={storeSelected?.description}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                              bgcolor: 'white',
                              width: '100%'
                            }}
                            onChange={e =>
                              setStoreSelected({
                                ...storeSelected,
                                description: e.target.value
                              })
                            }
                          />

                          <Div style={{ ...style_info, marginTop: 4, marginLeft: 8 }}>
                            Exemplo: Ofertas válidas somente este final de semana!
                          </Div>
                        </Div>
                      </form>

                      <Div style={{ marginTop: 8 }}>
                        {!layoutSelected?.video_template_id && (
                          <Button
                            variant="contained"
                            style={{ marginTop: 8 }}
                            onClick={() => {
                              if (!step2_incomplete) {
                                putLoja();
                                handleSubmit({ isPreview: true });
                                setBoxInFocus(3);
                              }
                            }}
                            disabled={step2_incomplete}
                            color="info"
                          >
                            Continuar
                          </Button>
                        )}
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Div>

              {!modelo_pronto && (
                <Div style={{ display: 'flex' }}>
                  <Div style={{ ...style_side_div }}>
                    <Div
                      style={{ ...style_circle }}
                      onClick={() => !step1_incomplete && !step2_incomplete && setBoxInFocus(3)}
                      // onClick={() => setBoxInFocus(3)}
                    >
                      4
                    </Div>
                    <Div style={{ ...style_line }}></Div>
                  </Div>
                  <Div style={{ width: '100%' }}>
                    <Div
                      style={{ ...style_titulo(boxInFocus === 3) }}
                      onClick={() => !step1_incomplete && !step2_incomplete && setBoxInFocus(3)}
                    >
                      Cores
                    </Div>
                    <Div
                      style={{
                        ...style_box,
                        ...(boxInFocus === 3 ? {} : { display: 'none' })
                      }}
                    >
                      <Div style={{ display: 'inline-block' }}>
                        <Div style={{ display: 'inline-block' }}>
                          <Div style={{ marginTop: 16 }}>*Não é obrigatório escolher cores</Div>
                          <Div style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
                            <Div
                              style={{
                                ...style_color_div
                              }}
                            >
                              <Div
                                style={{
                                  ...style_title_color
                                }}
                              >
                                Cor do Preço
                              </Div>
                              <Div
                                style={{
                                  margin: 'auto'
                                }}
                              >
                                <SketchPicker
                                  color={colorPrice}
                                  onChangeComplete={(color: any) => setColorPrice(color.rgb)}
                                  styles={{
                                    default: {
                                      picker: {
                                        boxShadow: 'none',
                                        margin: 'auto'
                                      }
                                    }
                                  }}
                                />
                              </Div>
                            </Div>

                            <Div
                              style={{
                                ...style_color_div
                              }}
                            >
                              <Div
                                style={{
                                  ...style_title_color
                                }}
                              >
                                Cor do Texto
                              </Div>
                              <Div
                                style={{
                                  margin: 'auto'
                                }}
                              >
                                <SketchPicker
                                  color={colorText}
                                  onChangeComplete={(color: any) => setColorText(color.rgb)}
                                  styles={{
                                    default: {
                                      picker: {
                                        boxShadow: 'none',
                                        margin: 'auto'
                                      }
                                    }
                                  }}
                                />
                              </Div>
                            </Div>
                            {layoutSelected?.video_template_id && (
                              <Div
                                style={{
                                  ...style_color_div_video
                                }}
                              >
                                <Div
                                  style={{
                                    ...style_title_color
                                  }}
                                >
                                  Cor da Mensagem
                                </Div>
                                <Div
                                  style={{
                                    margin: 'auto'
                                  }}
                                >
                                  <SketchPicker
                                    color={colorText2}
                                    onChangeComplete={(color: any) => setColorText2(color.rgb)}
                                    styles={{
                                      default: {
                                        picker: {
                                          boxShadow: 'none',
                                          margin: 'auto'
                                        }
                                      }
                                    }}
                                  />
                                </Div>
                              </Div>
                            )}
                          </Div>
                          <Div style={{ marginTop: 16 }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setAppliedColor(0);
                              }}
                              size="small"
                            >
                              Usar cor padrão
                            </Button>
                            <Button
                              variant="outlined"
                              style={{ marginLeft: 16 }}
                              onClick={() => {
                                setAppliedColor(prev => prev + 1);
                              }}
                              size="small"
                            >
                              Aplicar Cores
                            </Button>
                          </Div>
                        </Div>
                      </Div>
                      <Div style={{ marginTop: 16 }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setBoxInFocus(4);
                          }}
                          color="info"
                        >
                          Continuar
                        </Button>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              )}

              {!modelo_pronto && (
                <Div style={{ display: 'flex' }}>
                  <Div style={{ ...style_side_div }}>
                    <Div
                      style={{ ...style_circle }}
                      // onClick={() => !step1_incomplete && !step2_incomplete && setBoxInFocus(4)}
                      onClick={() => setBoxInFocus(4)}
                    >
                      5
                    </Div>
                    <Div style={{ ...style_line }}></Div>
                  </Div>

                  <Div style={{ width: '100%' }}>
                    <Div
                      style={{ ...style_titulo(boxInFocus === 4) }}
                      onClick={() => !step1_incomplete && !step2_incomplete && setBoxInFocus(4)}
                    >
                      Etiquetas
                    </Div>
                    {boxInFocus === 4 && (
                      <Div
                        style={{
                          ...style_box,
                          ...(boxInFocus === 4 ? {} : { display: 'none' })
                        }}
                      >
                        <Tabs
                          value={etiquetaTab}
                          onChange={(e, nv) => changeEtiquetaTab(nv)}
                          aria-label="basic tabs example"
                          sx={{
                            mb: 4
                          }}
                        >
                          <Tab label="Etiqueta única" />
                          {rodape_etiqueta_view && <Tab label="Etiqueta personalizada" />}
                        </Tabs>

                        {etiquetaTab === 0 && (
                          <TagDialog
                            open={openTagDialog}
                            tags={tagsList}
                            onClose={() => {
                              setOpenTagDialog(false);
                            }}
                            onChange={(value: any) => {
                              setTagSelected(value);
                            }}
                            products_list={productsList.filter(
                              (k: any) => !idsNaoIncluidos.find(n => n === k.id)
                            )}
                            set_products_list={setProductsList}
                            setChangeTagPersonalized={setChangeTagPersonalized}
                          />
                        )}

                        {etiquetaTab === 1 && (
                          <TagPersonalizedDialog
                            open={openTagDialog}
                            tags={tagsList}
                            onClose={() => {
                              setOpenTagDialog(false);
                            }}
                            onChange={(value: any) => {
                              setTagSelected(value);
                            }}
                            products_list={productsList.filter(
                              (k: any) => !idsNaoIncluidos.find(n => n === k.id)
                            )}
                            set_products_list={setProductsList}
                            setChangeTagPersonalized={setChangeTagPersonalized}
                          />
                        )}

                        {rodape_etiqueta_view && (
                          <Div style={{ marginTop: 16 }}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setBoxInFocus(5);
                              }}
                              color="info"
                            >
                              Continuar para rodapés
                            </Button>
                          </Div>
                        )}
                      </Div>
                    )}
                  </Div>
                </Div>
              )}

              {rodape_etiqueta_view && (
                <>
                  {!modelo_pronto && (
                    <Div style={{ display: 'flex' }}>
                      <Div style={{ ...style_side_div }}>
                        <Div
                          style={{ ...style_circle }}
                          // onClick={() => !step1_incomplete && !step2_incomplete && setBoxInFocus(5)}
                          onClick={() => setBoxInFocus(5)}
                        >
                          6
                        </Div>
                        <Div style={{ ...style_line }}></Div>
                      </Div>

                      <Div style={{ width: '100%' }}>
                        <Div
                          style={{ ...style_titulo(boxInFocus === 5) }}
                          onClick={() => !step1_incomplete && !step2_incomplete && setBoxInFocus(5)}
                        >
                          Rodapé
                        </Div>
                        {boxInFocus === 5 && (
                          <Div
                            style={{
                              ...style_box,
                              ...(boxInFocus === 5 ? {} : { display: 'none' })
                            }}
                          >
                            <RodapePersonalizedDialog
                              setRodapeSelected={setRodapeSelected}
                              storeSelected={storeSelected}
                            />
                          </Div>
                        )}
                      </Div>
                    </Div>
                  )}
                </>
              )}
            </Div>
          </Div>

          <Div style={{ height: 100 }}></Div>

          <Div
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              width: '100%',
              textAlign: 'center',
              padding: 24,
              backgroundColor: 'white'
            }}
          >
            <Button
              variant="contained"
              style={{ marginLeft: 8 }}
              onClick={event => {
                if (!(parseInt(localStorage.getItem('companyId') || '') === 1115)) {
                  handleSubmit({});
                  return;
                }

                if (layoutSelected?.video_template_id) {
                  handleSubmit({});
                  return;
                }

                handleClickGenerate(event);
              }}
              disabled={(!modelo_pronto && !backgroundSelected) || !layoutSelected || isSubmitting}
              color="info"
            >
              Salvar e gerar artes
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openToGenerate}
              onClose={handleCloseGenerate}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{
                '& .MuiPaper-root': {
                  borderRadius: 1,
                  minWidth: 260,
                  boxShadow:
                    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                  '& .MuiMenu-list': {
                    padding: '4px 0'
                  }
                }
              }}
            >
              <MenuItem onClick={() => handleSubmit({ isPNG: false })}>
                <Div style={{ fontSize: 14, margin: '8px 0px' }}>
                  <b>JPEG</b>
                  <Div>Ideal para uso digital</Div>
                </Div>
              </MenuItem>
              <MenuItem onClick={() => handleSubmit({ isPNG: true })}>
                <Div style={{ fontSize: 14, margin: '8px 0px' }}>
                  <b>PNG (qualidade mais alta)</b>
                  <Div>Ideal para impressões gráficas</Div>
                </Div>
              </MenuItem>
            </Menu>
          </Div>
        </Div>
      </Dialog>

      {isSubmitting && <Loader open={isSubmitting} />}

      {openFindBackgrounds && (
        <FindBackgrounds
          open={openFindBackgrounds}
          handleClose={() => setOpenFindBackgrounds(false)}
          segments={segments}
          formatType={formatType}
          setBackgroundSelected={setBackgroundSelected}
        />
      )}

      {openFindLayouts && (
        <FindLayouts
          open={openFindLayouts}
          handleClose={() => setOpenFindLayouts(false)}
          setLayoutSelected={setLayoutSelected}
          formatType={formatType}
          modeloPronto={modelo_pronto}
          qtdeProductsInList={productsList.length - idsNaoIncluidos.length}
        />
      )}

      {openSelecionarProdutos && (
        <SelecionarProdutos
          open={openSelecionarProdutos}
          onOpen={() => {
            //this.setState({ idsNaoIncluidos: [] });
          }}
          onClose={() => {
            setOpenSelecionarProdutos(false);
          }}
          tabloideProdutos={productsList.map((item: any) => ({
            ...item.fullData
          }))}
          idsNaoIncluidos={idsNaoIncluidos}
          onSave={(value: any) => {
            setIdsNaoIncluidos([...value]);
            setOpenSelecionarProdutos(false);
          }}
          onClickProduct={(product: any) => {
            onClickProduct(product, fetchProducts);
          }}
          fetchProductsCreateArt={fetchProducts}
          productsListLoading={productsListLoading}
        />
      )}

      {openDialogContinuar && (
        <Dialog
          open={openDialogContinuar}
          onClose={() => setOpenDialogContinuar(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Deseja gerar mais artes?'}</DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialogContinuar(false);
                handleClose();
              }}
              color="primary"
            >
              Não
            </Button>
            <Button
              onClick={() => {
                setOpenDialogContinuar(false);
                onCloseReturn();
              }}
              color="primary"
              autoFocus
            >
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openPreview && (
        <Dialog
          open={openPreview}
          onClose={() => setOpenPreview(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          // maxWidth={'lg'}
        >
          <PreviewFinalImage imageWidth={'100%'} />
        </Dialog>
      )}

      {openAdicionarProdutos && (
        <AdicionarProduto
          submiting={submiting}
          open={openAdicionarProdutos}
          produtoFoco={null}
          categorias={[]}
          categoriaPadrao={null}
          onSave={(data: any) => {
            const close = () => {
              handleCloseAdicionarProdutos();
            };
            updateProduto(data, 'add', close);
          }}
          onClose={() => {
            handleCloseAdicionarProdutos();
          }}
        />
      )}

      {importarTexto && (
        <ProductsText
          open={importarTexto}
          onSave={data => {
            importFileText(data);
            handleCloseImportarTexto();
          }}
          onClose={() => {
            handleCloseImportarTexto();
          }}
        />
      )}

      {importarArquivo && (
        <ProductsImport
          open={importarArquivo}
          onSave={data => {
            importFile(data);
          }}
          onClose={() => {
            handleDialogImportarArquivo();
          }}
        />
      )}

      {showDeleteDialog[0] && (
        <AlertDialogV2
          open={showDeleteDialog[0]}
          title="Deseja realmente excluir esse item?"
          textAgree="Sim"
          handleAgree={() => {
            showDeleteDialog[1]();
            setShowDeleteDialog([false, () => {}]);
          }}
          handleClose={() => setShowDeleteDialog([false, () => {}])}
        />
      )}

      {backgroundRemoveOpen && (
        <DialogBackgroundRemove
          setBackgroundRemoveYes={async (value: any) => {
            enqueueSnackbar('Enviando...', { variant: 'info' });
            setBackgroundRemoveOpen(false);
            const img = await postProdutoImagem(logoBase64, value);
            await putLoja(img);
            enqueueSnackbar('Logo adicionado com sucesso', { variant: 'success' });
          }}
        />
      )}
    </>
  );
}

const mapStateToProps = ({ TabloideDigitalReducer }) => ({
  ...TabloideDigitalReducer
});
const mapDispatchToProps = {
  postProdutoImagemTabloide
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateArt);

