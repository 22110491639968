import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { AMBIENTE, DATASALES_CRM_API } from '../../../../../utils/helpers/Constants';
import Fetch from '../../../../../utils/helpers/Fetch';
import { useStyles } from './styles';

const adjustImage = (formatId?: number) => {
  switch (formatId) {
    case 1:
      return '70ch';
    case 2:
    case 14:
    case 16:
      return '55ch';
    case 5:
      return '75ch';
    case 3:
      return '40ch';
    case 15:
      return '75ch';
    case 35:
      return '75ch';
    default:
      return '70ch';
  }
};

type ImagePolotnoProps = {
  design: string;
  designId: number;
  pixelRatio?: number;
  formatId?: number;
  onImageLoad?: (loading: boolean) => void;
  onBase64?: (image?: string) => void;
};

const ImagePolotno = ({
  design,
  pixelRatio,
  formatId,
  onImageLoad,
  onBase64,
  ...props
}: ImagePolotnoProps) => {
  const styles = useStyles();

  const { data, isFetching, isLoading } = useQuery(
    ['design-base64', design, pixelRatio],
    async () => {
      const response = await Fetch(
        `${DATASALES_CRM_API}flyers-lambda-${AMBIENTE}/thumbnail`,
        {
          designURL: design
        },
        'POST',
        null,
        null,
        false
      );

      return response;
    },
    {
      cacheTime: 20 * 1000
    }
  );

  useEffect(() => {
    if (onImageLoad) {
      onImageLoad(isFetching);
    }
  }, [isFetching, onImageLoad]);

  if (isLoading || (isFetching && !Boolean(onImageLoad))) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={adjustImage(formatId)}
        height="100%"
        className={styles.lazyComponents}
        sx={{ borderRadius: '8px' }}
      />
    );
  }

  return (
    <img {...props} src={data?.image} className={styles.lazyComponents} alt={`art-${formatId}`} />
  );
};

export default ImagePolotno;

